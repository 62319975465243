import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input']
  connect() {
    console.log('Hello, Stimulus!', this.element)
    // this.change()
  }

  change() {
    console.log(this.inputTarget.value)
  }
}
